<template>
  <div class="container-fluid">
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h3>Cadastro de tipo de treinamento</h3>
					</div>
				</div>
			</div>
			<form @submit.prevent="salvar">
				<div class="card-body">
					<div class="row">
						<div class="col-md-4 col-sm-12">
							<div class="form-group" :class="{ 'form-group--error': $v.tipoTreinamento.nome.$error }">
								<label for="nome" class="required">Nome</label>
								<input v-model="tipoTreinamento.nome" type="text" class="form-control" name="nome" id="nome">
								<div class="error-form text-danger" v-if="!$v.tipoTreinamento.nome.required">Campo obrigatório</div>
							</div>
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-md-12 mt-5">
							<button type="button" class="btn btn-danger float-right mx-3" @click="goBack">CANCELAR</button>
							<button type="submit" class="btn btn-primary float-right">SALVAR</button>
						</div>
					</div>
				</div>
			</form>
		</div>
  </div>
</template>

<script>
import treinamentoTiposRepository from './../../../services/api/treinamentoTiposRepository'
import { required } from 'vuelidate/lib/validators'
export default {
	validations: {
    tipoTreinamento: {
			nome: {
				required
			}
		}
  },
	data () {
		return {
			tipoTreinamento: {
        nome: ''
      }
		}
	},
	created () {
		if (this.$route.query.id) {
			this.buscaTipoTreinamento(this.$route.query.id)
		}
	},
	methods: {
		buscaTipoTreinamento (id) {
			treinamentoTiposRepository.buscaTipoTreinamento(id).then(response => {
				if(response.data['success']) {
					this.tipoTreinamento = response.data['data']
				}
			})
		},
		goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'TreinamentosTipos' })
    },
		salvar () {
			this.$v.$touch()
			if (this.$v.$invalid) {
        this.$swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Preencha o formulário corretamente!',
				})
      } else {
				if (this.tipoTreinamento.id) {
					treinamentoTiposRepository.atualizarTipoTreinamento(this.tipoTreinamento.id, this.tipoTreinamento).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Cadastro atualizado com sucesso!'
							}).then( () => {
								this.goBack()
							})
						}
					}).catch(error => {
						console.log(error)
						this.$swal({
							icon: 'error',
							title: 'Erro no cadastro!',
							text: error.message
						})
					})
				} else {
					treinamentoTiposRepository.salvarTipoTreinamento(this.tipoTreinamento).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Tipo de treinamento cadastrado com sucesso!'
							}).then( () => {
								this.goBack()
							})
						} else {
							this.$swal({
								icon: 'error',
								text: 'Não foi possível realizar o cadastro!'
							}).then( () => {
								this.goBack()
							})
						}
					}).catch(error => {
						this.$swal({
							icon: 'error',
							title: 'Erro no cadastro!',
							text: error.message
						})
					})
				}
			}
		}
	}
}
</script>

<style>

</style>